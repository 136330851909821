<template>
  <div ref="info_guide" class="info_guide" :class="guide_open">
    <!-- 오픈시 guide_open -->
    <a href="javascript:void(0);" class="link_guide" @click.prevent="toggleGuide"
      >이용안내<span class="ico_account"
    /></a>
    <div class="box_guide">
      <!--      <img src="http://via.placeholder.com/2200x450" alt="">-->
      <div class="txt_guide">
        <pre v-html="guideContents" />
      </div>
    </div>
    <!-- // box_use -->
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import { isEmpty, unescape } from "lodash";

export default {
  name: "DraftGuide",
  props: {
    type: [Number, String],
  },
  data() {
    return {
      guideContents: "",
      guide_open: "",
    };
  },
  mounted() {
    // this.$http.post('/api/selectUseGuideByFlag', { guideFlag: 1 })
    //   .then(response => {
    //     this.guideContents = response.data.contents
    //   })
    //   .catch(error => {
    //     console.log(error)
    //   })

    //TEST
    this.getGuide();
  },
  methods: {
    async getGuide() {
      // const guideNo = 1;
      const path = `${this.$apiPath.APRVL_GUIDE}?page=1&size=10`;
      const result = await ApiService.shared.getData(path);
      if (result.code == 200) {
        if (!result.data) {
          this.guideContents = "";
          return;
        }

        const guide = result.data;

        //구매요청 가이드인 경우
        if (this.type == 1) {
          this.guideContents = unescape(guide[0].contents);
        } else {
          this.guideContents = unescape(guide[1].contents);
        }
      }
      this.guide_open = "";
    },
    toggleGuide() {
      if (this.guide_open === "") {
        this.guide_open = "guide_open";
      } else {
        this.guide_open = "";
      }
    },
  },
};
</script>
